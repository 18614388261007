import React, { Component } from 'react';
import logo from './logo.png';
import morgondag from './morgondag.png';
import './App.css';

class App extends Component {
  render() {
    return (
      <div className="App">
        <header className="App-header">
          <a href="https://morgondag.io/collections/games/products/vast-void" title="Vast Void"><img src={logo} className="App-logo" alt="Vast Void" /></a>
          <p>Coming soon</p>
          <a
            className="App-link"
            href="https://morgondag.io"
            rel="noopener noreferrer"
          >
           Developed by Morgondag
          </a>
        </header>
        <a href="https://morgondag.io" title="Morgondag" rel="noopener noreferrer">
        <img src={morgondag} className="morgondag-logo" alt="morgondag" />
        </a>
      </div>
    );
  }
}

export default App;
